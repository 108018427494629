'use client';

import useMobileScreen from '@/hooks/useMobileScreen';
import Image from 'next/image';
import { forwardRef } from 'react';
import Background from '../../../../../../public/images/Home/Background element_Text.svg';
import MockupSlider2 from '../../../../../../public/images/Home/MockupSlider2.webp';
import ImageSlider2 from '../../../../../../public/images/Home/Slider2.webp';
import Pattern from '../../../../../../public/images/Pattern.png';
import WhiteStar from '../../../../../../public/images/WhiteStar.png';
import LeftSVG from '../../../../../../public/svgs/LeftSVG.svg';
import RightSVG from '../../../../../../public/svgs/RightSVG.svg';
import BuyNowBtn from './BuyNowBtn';

// const Slider2 = (props: any) => {
const Slider2 = forwardRef((props: any, ref: any) => {
  const { rightClick, leftClick } = props;
  const isMobile = useMobileScreen();

  if (isMobile) {
    return (
      // !h-[calc(100vh-48px)]
      <div ref={ref} className='relative !w-full bg-[#FFF4E4] pb-6'>
        {/* 45 85/115 + pt 24 */}
        <div className='mx-auto flex w-[90%] flex-col gap-6 pt-[132px] sm:pt-[192px]'>
          <div className='flex flex-col'>
            <span className='font-[wise] text-4xl font-medium text-zelene-dark-blue'>Giftcard is</span>
            <div className='flex items-center gap-4'>
              <div className='relative size-[60px]'>
                <Image src={Pattern} alt='background image' fill sizes='60px' className='object-contain' />
              </div>

              <span className='font-[wise] text-4xl font-medium text-zelene-dark-blue'>Available</span>

              <div className='relative size-10'>
                <Image src={WhiteStar} alt='background image' fill sizes='40px' className='object-contain' />
              </div>
            </div>
          </div>

          <span className='min-h-[72px] w-[95%] font-hneu text-[15px] sm:text-base'>
            Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.
          </span>

          <div className='flex items-center justify-between'>
            <BuyNowBtn />

            <div className='flex items-center gap-1'>
              <Image
                onClick={leftClick}
                src={LeftSVG}
                alt='left'
                width={32}
                height={32}
                className='size-[32px] cursor-pointer object-contain'
              />

              <div className='font-hneu text-base font-semibold text-zelene-red'>1 - 2</div>

              <Image
                onClick={rightClick}
                src={RightSVG}
                alt='right'
                width={32}
                height={32}
                className='size-[32px] cursor-pointer object-contain'
              />
            </div>
          </div>

          <div className='relative min-h-[406px] w-full rounded-lg bg-slate-200 pt-2'>
            <Image
              src={MockupSlider2}
              alt='slider2'
              className='!h-full !w-full rounded-lg object-cover'
              fill
              sizes='90vw'
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className='relative !h-[calc(100vh-48px)] !w-full bg-[#FFF4E4]'>
      <div className='absolute !left-0 bottom-0 !z-40 hidden w-full opacity-80 sm:block md:bottom-16 md:h-[180px]'>
        <Image
          width={600}
          height={180}
          alt='bg text'
          className='absolute h-auto w-[80%] object-contain md:w-[55%]'
          src={Background}
        />
      </div>

      <div className='!z-50 flex h-full w-full justify-between'>
        <div className='relative hidden flex-[7] bg-zelene-cream pt-[113px] md:flex lg:pt-[144px]'>
          <div className='absolute left-[15%] top-[212px] z-[60] flex w-full flex-col items-center justify-center md:left-1/2 lg:left-1/4'>
            <ContentSlider2 />
          </div>
        </div>

        {/* Right section */}
        <div className='relative z-10 mt-[45px] flex h-full w-full flex-[8] justify-start rounded-bl-[44px] rounded-tl-[44px] sm:h-full sm:!flex-[10] lg:!flex-[7]'>
          <Image
            src={ImageSlider2}
            alt='slider2'
            className='!h-full !w-full object-cover object-center'
            fill
            sizes='80vw'
          />

          <div className='left-1/4 top-[30%] !z-30 flex w-full flex-col items-center justify-center md:hidden'>
            <div className='z-30 flex flex-col font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue'>
              <span className='flex text-zelene-dark-blue'>
                Giftcard is{' '}
                <Image
                  src={WhiteStar}
                  alt='background image'
                  width={70}
                  height={70}
                  className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
                />
              </span>

              <div className='z-30 -mt-8 flex w-full items-center gap-6'>
                <Image
                  src={Pattern}
                  alt='background image'
                  width={113}
                  height={113}
                  className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[113px]'
                />

                <span className='font-[wise] text-[clamp(3.5rem,-1.0832rem_+_9.6118vw,8rem)] font-medium text-zelene-dark-blue sm:text-zelene-dark-blue'>
                  Available
                </span>
              </div>

              <div className='max-w-[300px] pb-6 font-hneu text-base leading-[21.6px] text-zelene-dark-blue sm:text-[18px] sm:text-gray-800'>
                Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift
                card.
              </div>

              <BuyNowBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Slider2;

const ContentSlider2 = () => {
  return (
    <div className='z-50 flex flex-col font-[wise] font-medium text-zelene-dark-blue'>
      <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
        Giftcard is
      </div>

      <div className='z-30 flex w-full items-center gap-6 lg:-mt-10'>
        <Image
          src={Pattern}
          alt='background image'
          width={113}
          height={113}
          className='!size-[40px] object-contain font-[wise] text-[128px] sm:!size-[60px] lg:!size-[113px]'
        />

        <div className='font-[wise] text-[clamp(3.8rem,-1.0832rem_+_9.6118vw,8rem)] leading-normal text-white sm:text-zelene-dark-blue'>
          Available
        </div>

        <Image
          src={WhiteStar}
          alt='background image'
          width={70}
          height={70}
          className='!size-[40px] object-contain font-[wise] text-[128px] md:!size-[70px]'
        />
      </div>

      <div className='max-w-[300px] pb-6 pt-3 font-hneu text-base leading-[21.6px] text-zelene-slight-blue'>
        Shopping for someone else but not sure what to give? Give them the gift of choice with a Zelene gift card.
      </div>

      <BuyNowBtn />
    </div>
  );
};
