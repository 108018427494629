import axiosClient from '@/helper/call-center';
import { IServiceNotRelation } from '@/interface/service';
import { handleCompressImage } from '@/utils/global-func';

export const ServiceApi = {
  findByCategory(categoryId: string) {
    return axiosClient.get(`/service/find-by-category/${categoryId}`);
  },
  findByCategoryAndStoreBranch(categoryId: string, storeBranchId: string) {
    return axiosClient.get(`/service/find-by-category-and-branch/${categoryId}/${storeBranchId}`);
  },
  findByLocationId(locationId: string) {
    return axiosClient.get(`/service/find-by-location/${locationId}`);
  },
  createNewService(params: IServiceNotRelation) {
    return axiosClient.post('/service', params, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  getDetailService(id: string) {
    return axiosClient.get(`/service/by-id/${id}`);
  },

  deleteServiceById(id: string) {
    return axiosClient.delete(`/service/${id}`);
  },
  editServiceById(params: any) {
    const { id, ...rest } = params;
    return axiosClient.put(`/service/by-id/${id}`, { ...rest });
  },

  async editImageServiceById(params: any) {
    const { id, file } = params;
    const formData = new FormData();
    const fileResize = await handleCompressImage(file);
    formData.append('file', fileResize, file.filename);

    return axiosClient.patch(`/service/by-id/${id}/update-image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getMoreServiceByLocation(location: string) {
    return axiosClient.get(`/service-category/more-service/${location}`);
  },
};
