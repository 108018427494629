'use client';

import { Carousel } from 'antd';
import { useRef, useState } from 'react';
import Slider2 from '../Slider2';
import Slider3 from '../Slider3';

const CarouselSlider = () => {
  const ref: any = useRef();
  const [nextIndex, setNextIndex] = useState(0);

  const beforeChange = (prev: number, next: number) => {
    setNextIndex(next);
  };

  const rightClick = () => {
    ref?.current?.next();
  };

  const leftClick = () => {
    ref?.current?.prev();
  };

  return (
    <Carousel
      ref={ref}
      beforeChange={beforeChange}
      dots={false}
      draggable
      initialSlide={0}
      className='!z-30'
      rootClassName='!z-30'
      autoplay
      autoplaySpeed={7000}
      infinite
    >
      {/* <div className='h-[600px] overflow-hidden pt-[116px] sm:!h-[1000px] sm:pt-[122px]'>
    <HeaderHero />
    <BackgroundText />
  </div> */}

      <Slider2 rightClick={rightClick} leftClick={leftClick} ref={ref} />
      <Slider3 rightClick={rightClick} leftClick={leftClick} ref={ref} />
    </Carousel>
  );
};

export default CarouselSlider;
